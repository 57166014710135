// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import Quote from '../components/shared/Quote';
import { quoteList } from '../utils/constants/testimonials';

// Component definition
const Testimonials = () => (
  <Layout>
    <Meta title="Testimonials" />
    <HeaderGeneric
      title="Presto Assistant Testimonials"
      subtitle="What people are saying"
    />

    <div id="main">
      <section id="content" className="main">
        <div id="quote-container">
          {quoteList.map((quote) => (
            <Quote
              district={quote.district}
              key={quote.name}
              message={quote.message}
              name={quote.name}
              school={quote.school}
              title={quote.title}
            />
          ))}
        </div>
      </section>
    </div>

  </Layout>
);

export default Testimonials;
