// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  district: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  school: PropTypes.string,
  title: PropTypes.string.isRequired,
};

// Component Definition
const Quote = ({
  district,
  message,
  name,
  school,
  title,
}) => (
  <div className="quote">
    <blockquote>&quot;{message}&quot;</blockquote>

    <div>
      <p className="quote-name">{name}</p>
      <p className="quote-title">{title}</p>
      <p className="quote-district">
        {[school, district].filter(Boolean).join(', ')}
      </p>
    </div>
  </div>
);

Quote.propTypes = propTypes;

export default Quote;
